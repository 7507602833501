import React, {useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import '../style/components/styles.css';
import '../style/css/index-style.css';
import {Autoplay, Pagination, Navigation, EffectFade} from 'swiper/modules';
import i18n from "../i18n";
import api from "../helpers/axios";
const Slider = () => {
    const [educations, setEducations] = useState([]);
    const [educationsFetched, setEducationsFetched] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (!educationsFetched) {
            api.get(`educations`, {
                headers: {
                    "domain": window.location.hostname,
                    "Content-Type": "application/json"
                }
            })
                .then(res => {
                    setEducations(res.data.docs);
                    setEducationsFetched(true);
                    setLoading(false);
                })
                .catch(err => {
                    setLoading(true);
                    console.log('Error:', err.message);
                })
        }
    }, [educationsFetched]);

    const reversedEducations = [...educations].reverse();
    return (
        <>{loading ? <>

            </> :
            <>
                <div className="form-page-left-content d-flex align-items-center justify-content-center w-100">
                    <div className="d-flex w-100">
                        <Swiper
                            spaceBetween={30}
                            centeredSlides={true}
                            autoplay={{
                                pauseOnMouseEnter: true,
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={false}
                            modules={[Autoplay,
                                Pagination, Navigation,EffectFade]}
                            className="mySwiper w-100"
                            effect="fade">
                            {reversedEducations.map((item, index) => {
                                return (
                                    <SwiperSlide className="swiper-slide-content align-items-start w-100" key={index}>
                                        <div className="inlineSwiper d-flex w-auto flex-column align-items-center " >
                                            <img className={"banner-image"} src={`${item?.image}`} alt="banner-image"/>
                                            <h2 className="banner-heading mt-5 mb-4 ">{item.title}</h2>
                                            <div className="mainCont container">
                                                <div
                                                    className={` ${item?.level?.length <= 4 ? `row col-12 list-group-vertical justify-content-center p-0 m-0 gap-md-3 d-flex lowerRowContent` : `row list-group-vertical justify-content-center d-flex gap-md-3 lowerRowContent`}`}>
                                                    {item?.level?.map(levels => {
                                                        const level = levels.name.split(' ')[0]; // İlk boşluğa kadar olan kısmı alır
                                                        return (
                                                            <div key={levels.id}
                                                                 className={`  ${item?.level?.length <= 4 ? `lowerRow1 d-flex align-items-center justify-content-start p-0` : `lowerRow2 d-flex align-items-center justify-content-start p-0`}`}>
                                                                <div
                                                                    className="d-flex justify-content-center align-items-center">
                                                                    <div
                                                                        className="d-flex align-items-center levelTag text-white justify-content-center">{level}</div>
                                                                    <div
                                                                        className="cardText d-flex flex-column justify-content-center align-content-centers">
                                                                        <p className="text-head p-0 m-0 text-start">{levels.title}</p>
                                                                        <p className="text-inf m-0 p-0 text-start">{levels.description}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            <p className={"mt-4 pb-5 mb-5 banner-subtitle"}>{i18n.t("slider.subTitle")}</p>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                </div>
            </>}

        </>

    );
}
export default Slider;

